var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "title" }, [_vm._v("INVOICE")]),
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "barcode" },
        [
          _c(
            "barcode",
            { attrs: { value: _vm.code, width: "2", marginLeft: "0" } },
            [_vm._v(" Show this if the endering fa s. ")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "info" }, [
        _c("p", { staticClass: "row" }, [
          _c("label", { staticClass: "row-title" }, [_vm._v("Count ")]),
          _c("label", { staticClass: "row-value" }, [
            _vm._v(_vm._s(_vm.count)),
          ]),
        ]),
        _c("p", { staticClass: "row" }, [
          _c("label", { staticClass: "row-title" }, [_vm._v("Weight ")]),
          _c("label", { staticClass: "row-value" }, [
            _vm._v(_vm._s(_vm.weight) + "g"),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "tips" }, [_vm._v("Don't rip it up.")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }