
import { Component, Vue } from 'vue-property-decorator'
// eslint-disable-next-line
const VueBarcode = require('vue-barcode')
// import * as VueBarcode from 'vue-barcode'
@Component({
	components: {
		barcode: VueBarcode
	}
})
export default class OPSLabels extends Vue {
	private code = 'A000000001'
	private count = 0
	private weight = 0
	mounted() {
		const { code, count, weight } = this.$route.query
		if (code != null) {
			console.log(code)
			this.code = (code || '') as string
			this.count = count != null ? Number(count) : 0
			this.weight = weight != null ? Number(weight) : 0
		}
	}
}
